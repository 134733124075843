<template>
	<div>
		<h1 class="main-title">{{ title }}</h1>
		<v-filters :urlFilters="urlFilters" :optionsDevice="optionsDevice"/>
		<br/>
		<br/>
		<p class="sessions-total">Всего сессий: {{ sortedSessions.length }}</p>
		<v-data-table
			class="sessions-table"
			:headers="headers"
			:items="sortedSessions"
			:loading="isLoading"
			hide-default-footer
			disable-pagination
		>
			<template v-slot:item.sessionId="{ item }">
				<a href="" @click="openDialog($event ,item)">
					{{item.sessionId}}
				</a>
			</template>
		</v-data-table>
		<v-dialog v-model="showDialog" max-width="300px">
			<v-export-dialog
				v-bind="{
					...dialogProps
				}"
			/>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { OPTIONS_SOURCE } from '@/const'
import { OPTIONS_DATA_TYPE, OPTIONS_PROCESSING_STATUS, OPTIONS_RECOLLECT, OPTIONS_DEVICE } from './filtersOptions'
import { validateFilterFromOptions } from '@/function/validateFilter'

import filters from './components/filters.vue'
import exportDialog from './components/exportDialog.vue'

export default {
	metaInfo () {
		return {
			title: this.title
		}
	},
	components: {
		'v-filters': filters,
		'v-export-dialog': exportDialog
	},
	data () {
		return {
			isLoading: false,
			showDialog: false,
			dialogProps: {}
		}
	},
	computed: {
		...mapState({
			sessionsLog: state => state.sessionsLog.sessionsLog,
			flukeSessionsLog: state => state.sessionsLog.flukeSessionsLog
		}),
		isSeoapiLogPage () {
			return this.$route.name === 'data-sessions-log'
		},
		title () {
			return this.isSeoapiLogPage ? 'Сессии SeoApi LOG' : 'Сессии Fluke LOG'
		},
		urlFilters () {
			const {
				date_from,
				date_to,
				session_id,
				recollect,
				session_processing_status,
				data_type,
				device,
				source
			} = this.$route.query

			const today = new Date().toISOString().split('T')[0]

			return {
				dateFrom: date_to && date_from ? date_from : today,
				dateTo: date_to && date_from ? date_to : today,
				dataType: this.isSeoapiLogPage ? validateFilterFromOptions(data_type, OPTIONS_DATA_TYPE, 'organic') : undefined,
				sessionId: session_id || undefined,
				recollect: this.isSeoapiLogPage ? validateFilterFromOptions(recollect, OPTIONS_RECOLLECT) : undefined,
				sessionProcessingStatus: validateFilterFromOptions(session_processing_status, OPTIONS_PROCESSING_STATUS),
				device: validateFilterFromOptions(device, this.optionsDevice),
				source: validateFilterFromOptions(source, OPTIONS_SOURCE)
			}
		},
		headers () {
			const headers = [
				{
					text: 'ID сессии',
					value: 'sessionId',
					align: 'center',
					width: '20%',
					divider: true
				},
				{
					text: 'Дата',
					value: 'date',
					align: 'center',
					divider: true
				},
				{
					text: 'Тип данных',
					value: 'type',
					align: 'center',
					divider: true
				},
				{
					text: 'Статус обработки сессии',
					value: 'sessionProcessingStatus',
					align: 'center',
					divider: true
				},
				{
					text: 'Устройство',
					value: 'device',
					align: 'center',
					divider: true
				},
				{
					text: 'Кол-во запросов',
					value: 'countQueries',
					align: 'center',
					divider: true
				},
				{
					text: 'Поисковая система',
					value: 'source',
					align: 'center',
					divider: true
				},
				{
					text: 'Номер пересбора',
					value: 'recollectIteration',
					align: 'center',
					divider: true
				},
				{
					text: 'Inserted_at',
					value: 'insertedAt',
					align: 'center',
					divider: true
				}
			]
			return this.isSeoapiLogPage ? headers : headers.filter(header => header.value !== 'type' && header.value !== 'recollectIteration')
		},
		optionsDevice () {
			return this.isSeoapiLogPage ? OPTIONS_DEVICE : OPTIONS_DEVICE.filter(option => option.id === 'mobile' || option.id === 'desktop')
		},
		sortedSessions () {
			const sessions = this.isSeoapiLogPage ? this.sessionsLog : this.flukeSessionsLog
			return sessions.map(item => {
				const insertedAt = item.inserted_at && item.inserted_at.date && item.inserted_at.date.split('.')[0]
				const [date, time] = insertedAt.split(' ')
				const dateFormatted = item.date && item.date.date && item.date.date + 'Z'
				return {
					date: dateFormatted && new Date(dateFormatted).toISOString().split('T')[0],
					timestamp: dateFormatted && new Date(dateFormatted).getTime(),
					sessionId: item.sessionId,
					type: item.type,
					sessionProcessingStatus: item.sessionProcessingStatus,
					device: item.device,
					source: item.source,
					countQueries: item.countQueries,
					recollectIteration: item.recollectIteration,
					insertedAt: `${time}, ${date}`
				}
			}).sort((a, b) => b.timestamp - a.timestamp)
		}
	},
	async created () {
		await this.initData()
	},
	methods: {
		...mapActions({
			getSessionsLog: 'sessionsLog/getSessionsLog',
			getFlukeSessionsLog: 'sessionsLog/getFlukeSessionsLog'
		}),
		async initData () {
			const { dateFrom, dateTo, dataType, sessionId, recollect, sessionProcessingStatus, device, source } = this.urlFilters
			const requestFunction = this.isSeoapiLogPage ? this.getSessionsLog : this.getFlukeSessionsLog
			this.isLoading = true
			await requestFunction({
				query: {
					date_from: dateFrom,
					date_to: dateTo,
					data_type: dataType,
					session_id: sessionId,
					recollect: recollect,
					session_processing_status: sessionProcessingStatus,
					device: device,
					source: source
				}
			})
			this.isLoading = false
		},
		openDialog (evt, item) {
			evt.preventDefault()
			this.dialogProps = {
				sessionId: item.sessionId,
				source: item.source,
				type: item.type,
				domain: this.isSeoapiLogPage ? 'http://seoapi-plt.hqsw.ru' : 'http://fluke-proxy.hqsw.ru',
				onClose: () => {
					this.showDialog = false
					this.dialogProps = {}
				}
			}
			this.showDialog = true
		}
	},
	watch: {
		urlFilters () {
			this.initData()
		}
	}
}
</script>

<style lang="scss" scoped>
.main-title {
	margin-bottom: 20px;
}
.sessions-total {
	text-align: center;
}
.sessions-table {
	margin-top: 24px;
	border-top: 1px solid rgba(0,0,0,.12);
}
</style>
